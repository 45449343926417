














import useAttachment from '@/use/attachment'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(_, { root }) {
    const { downloadAttachment, truncateFileName } = useAttachment({ root })

    return { downloadAttachment, truncateFileName }
  }
})
